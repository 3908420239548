import React from 'react'
import PropTypes from 'prop-types'

import ModalButton from '../modal-button'
import ModalWrapper from '../modal-wrapper'
import ProductDescription from '../product-description/ProductDescription'
import Suggestions from '../suggestions'
import Title from '../title'

import * as styles from './ymal-modal.module.scss'

const YmalModal = ({ product, ymal, background, inline }) => {
	const modalButton = ModalButton({
		type: inline ? 'inlineYmal' : 'ymal',
		analytics: { button: 'Open You may like Popup', product },
		children: <Suggestions ymal={ymal} />
	})

	return (
		<ModalWrapper button={modalButton}>
			<div
				className={`${styles.container} ${
					background ? styles[background] : null
				}`}
			>
				<Title ymalModal>You May Also Like</Title>
				<div className={styles.flexContainer}>
					{ymal?.map((product, i) => (
						<div className={styles.productContainer} key={i}>
							<ProductDescription ymal product={product} />
						</div>
					))}
				</div>
			</div>
		</ModalWrapper>
	)
}

YmalModal.propTypes = {
	product: PropTypes.string.isRequired,
	ymal: PropTypes.arrayOf(PropTypes.object).isRequired,
	background: PropTypes.string,
	inline: PropTypes.bool
}

export default YmalModal
