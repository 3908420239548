import React from 'react'
import PropTypes from 'prop-types'

import { LocateProduct, InlineYmal } from './Buttons'
import AnalyticsWrapper from '../analytics-wrapper'

const ModalButton = ({ type, analytics, children }) => (
	<AnalyticsWrapper {...analytics}>
		{type === 'locate' && <LocateProduct />}
		{type === 'inlineYmal' && <InlineYmal />}
		{type === 'ymal' && <>{children}</>}
	</AnalyticsWrapper>
)

ModalButton.propTypes = {
	type: PropTypes.string.isRequired,
	analytics: PropTypes.objectOf(PropTypes.string).isRequired,
	children: PropTypes.node
}

export default ModalButton
