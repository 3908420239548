// extracted by mini-css-extract-plugin
export var container = "ymal-modal-module--container--ceexV";
export var flexContainer = "ymal-modal-module--flex-container--QeY-L";
export var inline = "ymal-modal-module--inline--XiSGl";
export var productContainer = "ymal-modal-module--product-container--sEEIc";
export var shelf1 = "ymal-modal-module--shelf1--Oyju1";
export var shelf2 = "ymal-modal-module--shelf2--Zrm1N";
export var shelf3 = "ymal-modal-module--shelf3--sxRej";
export var shelf4 = "ymal-modal-module--shelf4--Chwtp";
export var shelf5 = "ymal-modal-module--shelf5--BjlXh";
export var shelf6 = "ymal-modal-module--shelf6--rZxx4";