import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import AnalyticsWrapper from '../analytics-wrapper'

import * as styles from './back-button.module.scss'

const onClickHandler = () => navigate(-1)

const BackButton = ({ product }) => {
	const analyticsData = {
		button: 'Back Button',
		product
	}

	return (
		<AnalyticsWrapper {...analyticsData}>
			<div className={styles.button} onClick={onClickHandler}>
				<div className={styles.chevron}></div>
			</div>
		</AnalyticsWrapper>
	)
}

BackButton.propTypes = {
	product: PropTypes.string.isRequired
}

export default BackButton
