import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './tap-and-explore.module.scss'

const TapAndExplore = ({ inlineADA }) => (
	<div className={inlineADA ? styles.inlineADAContainer : styles.container}>
		<p className={styles.tap}>Tap &</p>
		<p className={styles.explore}>Explore</p>
		<div className={styles.chevrons}>
			<StaticImage src='../../images/ada-chevron.svg' alt='chevrons' />
		</div>
	</div>
)

export default TapAndExplore
