import React from 'react'
import * as styles from './trademark.module.scss'

const Trademark = () => (
	<div className={styles.trademark}>
		©️ Johnson & Johnson Consumer Inc. {new Date().getFullYear()}
	</div>
)

export default Trademark
