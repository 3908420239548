import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

import AnalyticsWrapper from '../analytics-wrapper'

import * as styles from './modal-wrapper.module.scss'

Modal.setAppElement('#___gatsby')

const ModalWrapper = ({ button, noPadding, children }) => {
	const [modalIsOpen, setIsOpen] = React.useState(false)
	const openModal = () => setIsOpen(true)
	const closeModal = () => setIsOpen(false)

	const analyticsData = {
		product: noPadding ? 'Locate Product Popup' : 'You may like Popup',
		button: 'Close Popup'
	}

	return (
		<div>
			<div onClick={openModal}>{button}</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				className={`${styles.modal} ${noPadding ? styles.noPadding : ''}`}
				overlayClassName={styles.overlay}
			>
				{children}
				<AnalyticsWrapper {...analyticsData}>
					<div className={styles.close} onClick={closeModal}>
						<StaticImage
							src='../../images/cancel.svg'
							width={25.2}
							alt='close'
						/>
					</div>
				</AnalyticsWrapper>
			</Modal>
		</div>
	)
}

ModalWrapper.propTypes = {
	button: PropTypes.object,
	noPadding: PropTypes.bool,
	children: PropTypes.node.isRequired
}

export default ModalWrapper
