import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './modal-button.module.scss'

export const LocateProduct = () => (
	<div className={styles.locate}>
		<StaticImage
			className={styles.iconContainer}
			src='../../images/locate.svg'
			alt='locate icon'
		/>
		<p>
			Locate <br /> Product
		</p>
	</div>
)

export const InlineYmal = () => (
	<div className={styles.locate}>
		<StaticImage
			className={styles.iconContainer}
			src='../../images/ymal.svg'
			alt='ymal icon'
		/>
		<p>
			You May <br /> Also Like
		</p>
	</div>
)
