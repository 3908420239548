// extracted by mini-css-extract-plugin
export var brandName = "product-description-module--brand-name--jL+wq";
export var buttonsContainer = "product-description-module--buttons-container--hQOJG";
export var image = "product-description-module--image--Plgp5";
export var list = "product-description-module--list--om5nj";
export var name = "product-description-module--name--Sy1Ns";
export var productContainer = "product-description-module--product-container--f+Yvq";
export var productContainerNoPadding = "product-description-module--product-container-no-padding--KUuv8";
export var textContainer = "product-description-module--text-container--oXnXR";
export var warning = "product-description-module--warning--By7BZ";
export var ymalImage = "product-description-module--ymal-image--DhBxA";
export var ymalProduct = "product-description-module--ymal-product--TaOKn";