import React from 'react'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'

import { getSlideshowImages } from 'hooks'
import { filterSlideshowImages } from 'utils/graphql'

import 'styles/slick/slick.scss'
import * as styles from './slideshow.module.scss'

const Slideshow = ({ collection, inline, product }) => {
	const images = filterSlideshowImages(
		getSlideshowImages(),
		collection,
		product,
		inline
	)

	const settings = {
		arrows: false,
		dots: false,
		fade: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		className: styles.slideContainer
	}

	return (
		<div className={styles.image}>
			<Slider {...settings}>
				{images.map((image, i) => (
					<GatsbyImage
						key={i}
						image={image.gatsbyImageData}
						alt={`${image.name} picture`}
					/>
				))}
			</Slider>
		</div>
	)
}

export default Slideshow
