// extracted by mini-css-extract-plugin
export var brandName = "locate-product-module--brand-name--7Uw1L";
export var circle = "locate-product-module--circle--5kOhC";
export var circleContainer = "locate-product-module--circle-container--MsI0o";
export var container = "locate-product-module--container--GwERy";
export var image = "locate-product-module--image--1sbnj";
export var imageContainer = "locate-product-module--image-container--EWA6M";
export var inlinePlanogramContainer = "locate-product-module--inline-planogram-container--RDd3i";
export var label = "locate-product-module--label--gy3Ip";
export var name = "locate-product-module--name--98jK+";
export var planogramContainer = "locate-product-module--planogram-container--TpDoI";
export var productData = "locate-product-module--product-data--kieme";
export var productHeader = "locate-product-module--product-header--ubylJ";
export var shelfId = "locate-product-module--shelf-id--njQHV";