import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import * as styles from './locate-product.module.scss'
import { convertRegisterdSymbol } from 'utils'

const LocateProductLayout = ({ product, inline }) => {
	product.location =
	typeof product.location === 'string'
	? product.location.split(',')
	: product.location
	// If the location parts are valid numbers, use pixels as unit, otherwise, use the location parts as is
	const locationUnits = product.location.every( num => isNaN(num)) ? '' : 'px';
	const leftValue = `${(product.location && product.location[0]) ?? 0}${locationUnits}`;
	const topValue = `${(product.location && product.location[1]) ?? 0}${locationUnits}`;
	return (
		<div className={styles.container}>
			<div className={styles.productHeader}>
				<div className={styles.image}>
					<GatsbyImage
						image={product.image.gatsbyImageData}
						alt={`${product.name} picture`}
					/>
				</div>
				<div className={styles.productData}>
					<p className={styles.brandName}>{convertRegisterdSymbol(product.brand_name)}</p>
					<p
						className={styles.name}
					>{convertRegisterdSymbol(product.collection_name)} {`${product.name}`}</p>
				</div>
			</div>
			<div
				className={
					inline ? styles.inlinePlanogramContainer : styles.planogramContainer
				}
			>
				<p className={styles.label}>Find the product on the shelf</p>
				<div className={styles.imageContainer}>
					<div className={styles.circleContainer}>
						<div
							style={{
								top: topValue,
								left: leftValue
							}}
							className={styles.circle}
						></div>
					</div>
					<StaticImage src='../../images/planogram.png' alt='planogram' />
				</div>
			</div>
			<div className={styles.shelfId}>
				<p>Shelf #{inline ? product.shelf_id : product.collection_id}</p>
			</div>
		</div>
	)
}

export default LocateProductLayout
