import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import AnalyticsWrapper from '../analytics-wrapper'

import * as styles from './navigator.module.scss'

const Navigator = ({ totalCollections, id, ADA }) => {
	const text = `Shelf #${id}`
	const navigateDown = () =>
		navigate(`/collection${id === 1 ? totalCollections : id - 1}/`)
	const navigateUp = () =>
		navigate(`/collection${id === totalCollections ? 1 : id + 1}/`)

	const prevAnalyticsData = {
		product: text,
		button: 'prev shelf'
	}
	const nextAnalyticsData = {
		...prevAnalyticsData,
		button: 'next shelf'
	}

	return (
		<div className={styles.container}>
			<AnalyticsWrapper {...prevAnalyticsData}>
				<div
					className={ADA ? styles.buttonUp : styles.buttonLeft}
					onClick={navigateDown}
				>
					<div className={styles.chevronLeft}></div>
				</div>
			</AnalyticsWrapper>
			<div className={`${styles.text} ${ADA ? styles.adaText : ''}`}>
				{text}
			</div>
			<AnalyticsWrapper {...nextAnalyticsData}>
				<div
					className={ADA ? styles.buttonDown : styles.buttonRight}
					onClick={navigateUp}
				>
					<div className={styles.chevronRight}></div>
				</div>
			</AnalyticsWrapper>
		</div>
	)
}

Navigator.propTypes = {
	totalCollections: PropTypes.number.isRequired,
	id: PropTypes.number.isRequired,
	ADA: PropTypes.bool
}

export default Navigator
