import React, { useState, useEffect } from 'react'

import * as styles from './control-panel.module.scss'

const ControlPanel = () => {
	const [showPanel, setShowPanel] = useState(false)
	const [click, setClick] = useState(0)

	useEffect(() => {
		const timer = setTimeout(() => {
			if (click === 1) {
				setShowPanel(false)
			}
			setClick(0)
		}, 250)

		if (click === 2) {
			setShowPanel(true)
		}

		return () => clearTimeout(timer)
	}, [click])

	const onDoubleClickHandler = () => setClick(click + 1)

	return (
		<>
			<div
				className={`${styles.panelButton} ${
					!showPanel ? '' : styles.showButton
				}`}
				onClick={onDoubleClickHandler}
			>
				{showPanel && 'X'}
			</div>
			<iframe
				src={process.env.GATSBY_CP_URL ?? 'http://localhost:4000'}
				width='1080px'
				height='720px'
				id='cpIframe'
				className={`${styles.iframe} ${showPanel ? '' : styles.hidden}`}
			></iframe>
		</>
	)
}

export default ControlPanel
