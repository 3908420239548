import React from 'react'
import PropTypes from 'prop-types'
import { analyticsSend } from 'utils'

const AnalyticsWrapper = ({ button, product, children }) => {
	const onClickHandler = () => analyticsSend({ type: 'track', button, product })

	return <div onClick={onClickHandler}>{children}</div>
}

AnalyticsWrapper.propTypes = {
	button: PropTypes.string.isRequired,
	product: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
}

export default AnalyticsWrapper
