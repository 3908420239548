import React, { useContext } from 'react'
import { ADAContext } from 'context'
import { analyticsSend } from 'utils'

import * as styles from './toggle.module.scss'

const Toggle = ({ name, image, product }) => {
	const { ADA, setADA } = useContext(ADAContext)

	const changeHandler = e => {
		setADA(e.target.checked)
		analyticsSend({
			type: 'track',
			button: `ADA ${ADA ? 'disabled' : 'enabled'}`,
			product
		})
	}

	return (
		<div className={styles.container}>
			{image}
			<div className={styles.toggleSwitch}>
				<input
					onChange={changeHandler}
					type='checkbox'
					className={styles.toggleSwitchCheckbox}
					name={name}
					id={name}
					checked={ADA}
				/>
				<label className={styles.toggleSwitchLabel} htmlFor={name}>
					<span className={styles.toggleSwitchInner} />
					<span className={styles.toggleSwitchSwitch} />
				</label>
			</div>
		</div>
	)
}
export default Toggle
