import React from 'react'
import PropTypes from 'prop-types'

import Accessibility from '../accessibility'
import BackButton from '../back-button'
import Trademark from '../trademark'

import * as styles from './footer.module.scss'

const Footer = ({ product, backButton, trademark, ADA }) => {
	return (
		<div>
			{backButton && (
				<div className={styles.backbuttonContainer}>
					<BackButton product={product} />
				</div>
			)}
			{trademark && (
				<div className={styles.trademarkContainer}>
					<Trademark />
				</div>
			)}
			{ADA && (
				<div className={styles.a11yContainer}>
					<Accessibility product={product} />
				</div>
			)}
			<div className={styles.buttonPlaceholder}></div>
		</div>
	)
}

Footer.propTypes = {
	product: PropTypes.string.isRequired,
	backButton: PropTypes.bool,
	trademark: PropTypes.bool,
	ADA: PropTypes.bool
}

export default Footer
