import React from 'react'
import PropTypes from 'prop-types'

import AnalyticsWrapper from '../analytics-wrapper'
import * as styles from './attract-navigator.module.scss'

const AttractNavigator = ({ id, collectionNames, upAction, downAction }) => {
	const prevAnalyticsData = {
		product: "Attract Carousel",
		button: 'Prev shelf'
	}
	const nextAnalyticsData = {
		...prevAnalyticsData,
		button: 'Next shelf'
	}

	return (
		<div className={styles.container}>
			<AnalyticsWrapper {...prevAnalyticsData}>
				<div className={styles.buttonUp} onClick={downAction}>
					<div className={styles.chevronLeft}></div>
				</div>
			</AnalyticsWrapper>
			<div className={`${styles.text} ${styles.adaText}`}>
				{`${collectionNames[id - 1]}`}
			</div>
			<AnalyticsWrapper {...nextAnalyticsData}>
				<div className={styles.buttonDown} onClick={upAction}>
					<div className={styles.chevronRight}></div>
				</div>
			</AnalyticsWrapper>
		</div>
	)
}

AttractNavigator.propTypes = {
	id: PropTypes.number.isRequired,
	collectionNames: PropTypes.arrayOf(PropTypes.string).isRequired,
	upAction: PropTypes.func.isRequired,
	downAction: PropTypes.func.isRequired
}

export default AttractNavigator
