import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import AnalyticsWrapper from '../analytics-wrapper'

import * as styles from './button.module.scss'

const Button = ({ text, url, type, analytics }) => (
	<AnalyticsWrapper {...analytics}>
		<Link to={url} className={styles[type]}>
			{text}
		</Link>
	</AnalyticsWrapper>
)

Button.propTypes = {
	text: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	type: PropTypes.string,
	analytics: PropTypes.objectOf(PropTypes.string).isRequired
}

export default Button
