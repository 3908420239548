import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Toggle } from 'components'

import * as styles from './accessibility.module.scss'

export const Accessibility = ({ showQR, product }) => {
	const a11yIcon = showQR ? (
		<StaticImage
			src='../../images/accessibility-white.svg'
			alt='Accessibility logo'
		/>
	) : (
		<StaticImage
			src='../../images/accessibility.svg'
			alt='Accessibility logo'
		/>
	)

	return showQR ? (
		<div className={styles.qrIcon}>{a11yIcon}</div>
	) : (
		<div className={styles.main}>
			<Toggle image={a11yIcon} product={product} name='a11y' />
		</div>
	)
}

export default Accessibility
