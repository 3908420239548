import React, { useRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import CarouselCard from './CarouselCard'

import 'styles/slick/slick.scss'
import * as styles from './Carousel.module.scss'
import { useEffect } from 'react'

const settings = {
	className: styles.carouselContainer,
	focusOnSelect: true,
	infinite: true,
	slidesToShow: 5,
	slidesToScroll: 1,
	speed: 500
}

const Carousel = ({ images, label, clickHandler, initialIndex}) => {
	let slider = useRef(null);

	useEffect( () => {
		if (initialIndex) {
			slider.current.slickGoTo(initialIndex, true);
		}
	}, [initialIndex])

	return (
		<div className={styles.container}>
			{label && <p className={styles.label}>Products in the collection</p>}
			<Slider {...settings} ref={slider}>
				{images.map((image, i) => (
					<CarouselCard
						key={i}
						image={image}
						clickHandler={clickHandler}
						index={i}
					/>
				))}
			</Slider>
		</div>
	)
}

Carousel.propTypes = {
	images: PropTypes.array.isRequired,
	label: PropTypes.bool,
	clickHandler: PropTypes.func.isRequired
}

export default Carousel
