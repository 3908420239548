import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './title.module.scss'

const Title = ({ h1, ymalModal, children }) => {
	return (
		<>
			{h1 && <h1 className={styles.h1}>{children}</h1>}
			{ymalModal && <h1 className={styles.ymalModalTitle}>{children}</h1>}
		</>
	)
}

Title.propTypes = {
	h1: PropTypes.bool,
	ymalModal: PropTypes.bool
}

export default Title
