import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './suggestions.module.scss'

const Suggestions = ({ ymal }) => {
	return (
		<div className={styles.container}>
			<p className={styles.label}>You may also like</p>
			<div className={styles.flexContainer}>
				{ymal.map(product => (
					<div key={product.id} className={styles.imageContainer}>
						{product.image && (
							<GatsbyImage
								image={product.image.gatsbyImageData}
								alt={`${product.name} picture`}
								className={styles.image}
							/>
						)}
						<div className={styles.circle}></div>
					</div>
				))}
			</div>
		</div>
	)
}

Suggestions.propTypes = {
	ymal: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Suggestions
