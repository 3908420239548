import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import ProductButtons from '../product-buttons'

import * as styles from './product-description.module.scss'
import { convertRegisterdSymbol } from 'utils'

const ProductDescription = ({ product, ymal, ymalProducts, inline }) => (
	<div
		className={
			ymal ? styles.productContainerNoPadding : styles.productContainer
		}
	>
		{product.image && (
			<div className={ymal ? styles.ymalImage : styles.image}>
				<GatsbyImage
					image={product.image.gatsbyImageData}
					alt={`${product.name} picture`}
					className={ymal ? styles.ymalProduct : ''}
				/>
				{ymal ? (
					<div className={styles.warning}>
						<span>Use product as directed</span>
					</div>
				) : (
					<div className={styles.buttonsContainer}>
						<ProductButtons
							inline={inline}
							product={product}
							ymalProducts={ymalProducts}
						/>
					</div>
				)}
			</div>
		)}
		<div className={styles.textContainer}>
			<p className={styles.brandName}> {convertRegisterdSymbol(product.brand_name)}</p>
			<p className={styles.name}>
				{convertRegisterdSymbol(product.collection_name)} {product.name}
			</p>
			<ul className={styles.list}>
				{product.description.map((text, i) => (
					<li key={i}>{convertRegisterdSymbol(text)}</li>
				))}
			</ul>
		</div>
	</div>
)

ProductDescription.propTypes = {
	product: PropTypes.object.isRequired,
	ymal: PropTypes.bool,
	inline: PropTypes.bool,
	ymalProducts: PropTypes.array
}

export default ProductDescription
