import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './main-logo.module.scss'

const MainLogo = () => (
	<div className={styles.logoContainer}>
		<StaticImage src='../../images/neutrogena-logo.svg' alt='Neutrogena logo' />
	</div>
)

export default MainLogo
