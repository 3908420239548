import React from 'react'
import PropTypes from 'prop-types'

import LocateProduct from '../locate-product'
import YmalModal from '../ymal-modal'

import * as styles from './product-buttons.module.scss'

const ProductButtons = ({ product, ymalProducts, inline }) => (
	<div className={`${styles.buttonsContainer} ${!inline ? styles.single : ''}`}>
		{inline && (
			<YmalModal
				inline
				product={product.name}
				ymal={ymalProducts}
				background='inline'
			/>
		)}
		<LocateProduct inline={inline} product={product} />
	</div>
)

ProductButtons.propTypes = {
	product: PropTypes.object.isRequired,
	ymalProducts: PropTypes.array.isRequired
}

export default ProductButtons
