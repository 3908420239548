import React from 'react'
import ModalButton from '../modal-button'
import ModalWrapper from '../modal-wrapper'
import LocateProductLayout from './LocateProductLayout'

const LocateProduct = ({ product, inline }) => {
	const modalButton = ModalButton({
		type: 'locate',
		analytics: { button: 'Open Locate Product popup', product: product.name }
	})

	return (
		<ModalWrapper noPadding button={modalButton}>
			<LocateProductLayout product={product} inline={inline} />
		</ModalWrapper>
	)
}

export default LocateProduct
