import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { analyticsSend } from 'utils'

import * as styles from './Carousel.module.scss'

const CarouselCard = ({ image, clickHandler, index }) => {
	const onClickHandler = useCallback(() => {
		clickHandler(index)
		analyticsSend({
			type: 'track',
			product: image.name,
			button: 'Open Product'
		})
	}, [index, image, clickHandler])

	return (
		<div className={styles.circle} onClick={onClickHandler}>
			<GatsbyImage
				image={image?.gatsbyImageData}
				alt={`${image?.name} picture`}
			/>
		</div>
	)
}

CarouselCard.propTypes = {
	image: PropTypes.object.isRequired,
	clickHandler: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired
}

export default CarouselCard
